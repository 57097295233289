import { renderBlocks } from '@/components/blocks';
import { NotFound } from '@/components/blocks/NotFound';
import { Layout } from '@/components/layout/Layout';
import { BASE_REVALIDATE } from '@/lib/constants';
import { useDynamicYield } from '@/lib/dynamicYield';
import {
  navigationQuery,
  navigations_exQuery,
  pageQuery,
  siteSettingsQuery,
} from '@/lib/queries';
import { getClient, readToken } from '@/lib/sanityUtils';
import { getValidLocale } from '@/lib/utils';

import type { SanityNavigationType, SanityPageType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';

const slug = '404';

export const getStaticProps = (async ({ locale, draftMode = false }) => {
  const client = getClient(draftMode ? { token: readToken } : undefined);

  const validLocale = getValidLocale(locale);

  const custom404 = await client.fetch<SanityPageType>(
    pageQuery({ locale: validLocale, slug }),
  );

  const siteSettings = await client.fetch<SiteSettings>(
    siteSettingsQuery({ locale: validLocale }),
  );

  const navigation = await client.fetch<SanityNavigationType>(
    navigationQuery({ locale: validLocale }),
  );

  const navigations_ex = await client.fetch<Navigation_exType[]>(
    navigations_exQuery({ locale: validLocale }),
  );

  return {
    props: {
      custom404,
      siteSettings,
      locale: validLocale,
      draftMode,
      navigation,
      navigations_ex,
      token: draftMode ? readToken : '',
    },
    revalidate: Number(process.env.REVALIDATE || BASE_REVALIDATE),
  };
}) satisfies GetStaticProps;

const Custom404 = ({
  custom404,
  siteSettings,
  locale,
  draftMode,
  navigation,
  navigations_ex,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { recommendationContext } = useDynamicYield(locale, 'OTHER');

  return (
    <Layout
      SEO={custom404?.SEO}
      siteSettings={siteSettings}
      preview={draftMode}
      navigation={navigation}
      navigations_ex={navigations_ex}
      recommendationContext={recommendationContext}
    >
      <NotFound />
      {renderBlocks(custom404?.blocks)}
    </Layout>
  );
};

export default Custom404;

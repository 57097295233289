import styled from '@emotion/styled';
import Image from 'next/image';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { Heading, Paragraph } from '@/components/common/MarkUp';
import { HeroSection } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';
import { usePageCommon } from '@/lib/usePageCommon';
import notFound from '@/public/images/not-found.png';

export interface NotFoundProps {}

const NotFound = () => {
  const { siteSettings } = usePageCommon();
  const newNavigation = siteSettings?.newNavigation;
  return (
    <HeroSection
      backgroundColour="--background-warm-base"
      verticalPadding={newNavigation ? 'default' : 'floating'}
      className="not-found"
    >
      <Container>
        <Stack spacing="--spacing-large">
          <HeadingLevelProvider level={1}>
            <Paragraph size="label-md">Page not found</Paragraph>
            <Heading size="heading-md">
              Oops, let&apos;s get you to a better place!
            </Heading>
            <StyledIllustration
              role="presentation"
              alt=""
              src={notFound}
              priority
            />
            <Paragraph size="body-lg">
              We couldn&apos;t find that page.
              <br /> It might have been moved, or there could be an error in the
              web address.
            </Paragraph>
            <Paragraph size="subheading-sm">Error code: 404</Paragraph>
            <Paragraph size="subheading-sm">
              Try retyping the address or go to our homepage
            </Paragraph>
            <Button variant="solid-light" iconPosition="left" href="/">
              Go to Multiverse home
            </Button>
          </HeadingLevelProvider>
        </Stack>
      </Container>
    </HeroSection>
  );
};

const Container = styled.div`
  ${Paragraph}, ${Heading}, a {
    align-self: center;
    text-align: center;
  }
`;

const StyledIllustration = styled(Image)`
  width: 60%;
  max-width: 400px;
  height: auto;
  margin: var(--spacing-medium) auto;
  ${screen.sm} {
    max-width: 500px;
  }
`;

export { NotFound };
